.breathing-bar {
  height: 6px;
  transition: width 1s linear;
  display: flex;
  display: flex;
  position: relative;
  z-index: 4;
  justify-content: center;
  text-transform: uppercase;
  align-items: center;
  font-size: 9px;
}

.breathing-bar--breathing {
  animation: breathing 3s ease-in-out infinite;
}

.breathing-bar--flicker {
  animation: flicker 0.5s ease-in-out infinite;
}

@keyframes breathing-red {
  0%,
  100% {
    background-color: #ff9494;
  }
  50% {
    background-color: rgba(86, 86, 82, 0.3);
    box-shadow: none;
  }
}

@keyframes breathing-blue {
  0%,
  100% {
    background-color: #83c9f8;
  }
  50% {
    background-color: rgba(86, 86, 82, 0.3);
    box-shadow: none;
  }
}

@keyframes breathing-green {
  0%,
  100% {
    background-color: #82ffac;
  }
  50% {
    background-color: rgba(86, 86, 82, 0.3);
    box-shadow: none;
  }
}

.breathing-bar--red {
  background-color: #ff9494;
  box-shadow: 0 0 10px #ff4949, 0 0 20px #ff4949, 0 0 30px #ff4949,
    0 0 40px #ff4949, 0 0 50px #ff4949, 0 0 60px #ff4949, 0 0 70px #ff4949;
}

.breathing-bar--active.breathing-bar--red {
  animation-name: breathing-red;
}

.breathing-bar--blue {
  background-color: #83c9f8;
  box-shadow: 0 0 10px #76d6ff, 0 0 20px #76d6ff, 0 0 30px #76d6ff,
    0 0 40px #76d6ff, 0 0 50px #76d6ff, 0 0 60px #76d6ff, 0 0 70px #76d6ff;
}

.breathing-bar--active.breathing-bar--blue {
  animation-name: breathing-blue;
}

.breathing-bar--green {
  background-color: #82ffac;
  box-shadow: 0 0 10px #6bf78b, 0 0 20px #6bf78b, 0 0 30px #6bf78b,
    0 0 40px #6bf78b, 0 0 50px #6bf78b, 0 0 60px #6bf78b, 0 0 70px #6bf78b;
}

.breathing-bar--active.breathing-bar--green {
  animation-name: breathing-green;
}

.breathing-bar--flicker.breathing-bar--red {
  animation-name: flicker-red;
}

.breathing-bar--flicker.breathing-bar--blue {
  animation-name: flicker-blue;
}

.breathing-bar--flicker.breathing-bar--green {
  animation-name: flicker-green;
}

@keyframes flicker-red {
  0%,
  100% {
    background-color: #ff9494;
  }
  50% {
    background-color: rgba(86, 86, 82, 0.3);
    box-shadow: none;
  }
}

@keyframes flicker-blue {
  0%,
  100% {
    background-color: #83c9f8;
  }
  50% {
    background-color: rgba(86, 86, 82, 0.3);
    box-shadow: none;
  }
}

@keyframes flicker-green {
  0%,
  100% {
    background-color: #82ffac;
  }
  50% {
    background-color: rgba(86, 86, 82, 0.3);
    box-shadow: none;
  }
}

.breathing-bar--idle {
  text-shadow: 0 0 10px rgba(9, 8, 8, 0.5);
  background-color: var(--font-color);
  padding: 1px 4px;
  position: relative;
  bottom: 2.5px;
}

.breathing-bar__text {
  text-shadow: 0 0 10px rgba(9, 8, 8, 0.4);
  background-color: var(--font-color);
  padding: 1px 4px;
  position: relative;
  bottom: 4px;
  font-size: 10px;
}

/* media query for bigger font size on desktop */
@media screen and (min-width: 468px) {
  .breathing-bar__text {
    font-size: 16px;
    bottom: 8px;
  }

  .breathing-bar--idle {
    font-size: 16px;
    bottom: 8px;
  }
}

.breathing-bar--red .breathing-bar__text {
  background-color: #fff;
  color: var(--red-team-color);
}

.breathing-bar--blue .breathing-bar__text {
  background-color: #fff;
  color: var(--blue-team-color);
}

.breathing-bar--green .breathing-bar__text {
  background-color: #fff;
  color: var(--green-team-color);
}
