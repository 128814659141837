.grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-gap: 0.2rem;
  margin: 0 auto;
  width: 100%;
  max-width: 1000px;
  max-height: 1000px;
  color: black;
  padding: 0 2px;
  position: relative;
  z-index: 9;
}

.grid--6 {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(6, 1fr);
}
