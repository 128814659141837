.card {
  background-color: var(--unrevealed-card-color);
  color: var(--unrevealed-card-font-color);
  border-radius: 3px;
  position: relative;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  padding: 0.35rem 0.2rem;
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
  font-size: 2.7vmin;
  display: flex;
  justify-content: center;
  align-items: center;

  border: 1px solid var(--unrevealed-card-color);
}

.card--red {
  background-color: var(--red-team-color);
  color: white;
}

.card--blue {
  background-color: var(--blue-team-color);
  color: white;
}

.card--green {
  background-color: var(--green-team-color);
  color: white;
}

.card--assassin {
  background-color: black;
  color: white;
}

.card--neutral {
  background-color: var(--neutral-card-color);
  color: var(--neutral-card-font-color);
}

.card__countdown {
  height: 5px;
  background-color: #ff9800;
  transition: width 1s linear;
}

.card--revealed {
  opacity: 0.5;
}

.card--last-revealed {
  border: 1px solid orange;
  /* glow in orange */
  box-shadow: 0 0 10px 0 orange;
}

.card__votes {
  position: absolute;
  display: flex;
  top: -2px;
  right: 5px;
  background-color: white;
  padding: 0 3px;
  color: black;
  font-size: 8px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.5);
}

.card.flash {
  animation: flash 0.5s;
}

@keyframes flash {
  0% {
    opacity: 0.7;
    box-shadow: 0 0 10px 0 #fff, 0 0 20px 0 #fff, 0 0 30px 0 #fff,
      0 0 35px 0 #ff9800, 0 0 40px 0 #ff9800;
  }
  100% {
    opacity: 1;
  }
}
