.game-view {
  background: linear-gradient(
    to right,
    var(--red-team-color),
    var(--blue-team-color)
  );
  height: 100dvh;
  width: 100vw;
}

.game-hud {
  position: absolute;
  bottom: 120px;
}

.settings-btn {
  position: absolute;
  bottom: 10px;
  right: -2px;
  font-size: 16px;
  background-color: rgba(44, 64, 85, 1);
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

.timer-controls {
  position: absolute;
  top: -1px;
  right: -1px;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.timer-control-btn {
  font-size: 16px;
  background-color: rgba(44, 64, 85, 0.75);
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

.timer-control-btn--shuffle {
  /* top: 35px; */
}

.spectators-btn {
  position: absolute;
  bottom: 10px;
  left: -2px;
  font-size: 16px;
  background-color: rgba(44, 64, 85, 1);
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
  cursor: pointer;
  z-index: 1;
}

.spectators-btn__count {
  position: absolute;
  top: -5px;
  right: -5px;
  font-size: 9px;
  background-color: white;
  color: black;
  padding: 1px 2px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
}

.timer-control-btn--pulsing {
  animation: gear-pulse 0.9s infinite;
}

@keyframes gear-pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(78, 75, 75, 0.9);
  }
  50% {
    box-shadow: 0 0 0 20px rgba(77, 170, 87, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(77, 170, 87, 0);
  }
}
