.settings {
  color: white;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  z-index: 10;
  padding: 20px;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  padding-top: 100px;
}

.settings__overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.2);
}

.settings__container {
  background-color: rgba(44, 64, 85, 0.9);
  max-width: 420px;
  width: 100%;
  padding: 16px;
  border-radius: 4px;
  animation: slide-in 0.2s ease-out forwards;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  z-index: 20;
}

.settings__container--bottom {
  margin-top: auto;
  margin-bottom: 80px;
}

@keyframes slide-in {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

.settings__general {
  display: flex;
  justify-content: center;
  column-gap: 8px;
  margin-bottom: 8px;
}

.settings__config {
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding-bottom: 12px;
  margin-bottom: 12px;
  row-gap: 8px;
  flex-direction: column;
  margin-top: 20px;
}

.settings__config--only {
  margin-bottom: 0;
  border-bottom: 0;
  padding-bottom: 6px;
  border: 2px solid rgba(255, 255, 255, 0.2);
  padding: 20px;
  padding-top: 0;
  margin-top: 0;
}

.settings__config--actions {
  display: flex;
  column-gap: 8px;
}

.settings__config--allocations {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 12px;
  justify-content: center;
}

.settings__config--allocations p {
  text-align: center;
}

/* .settings__config--allocations div:first-of-type {
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  padding-right: 10px;
} */

.settings__create-game-btn {
  margin-top: 0;
  margin-bottom: 5px;
  font-size: 16px;
  padding: 6px 14px;
  border-radius: 4px;
  background-color: var(--green-team-color);
  color: #fff;
  border: 3px solid var(--green-team-color);
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  font-family: "Oswald", sans-serif;
  font-weight: bold;
  box-shadow: 0 0 4px #171818, 0 0 8px #171818, 0 0 10px #171818;
  letter-spacing: 0.8px;
}

input[type="radio"] {
  display: none;
}

label {
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 1px solid #fff;
  border-radius: 2px;
  text-align: center;
  line-height: 26px; /* Adjust for vertical centering */
  cursor: pointer;
  margin-right: 5px; /* Space between squares */
}

input[type="radio"]:checked + label {
  background-color: rgb(57, 175, 184);
  color: white; /* White text for checked state */
}

button.settings-close-btn {
  background: none;
  color: white;
  font-size: 20px;
  padding: 0;
  box-shadow: none;
  position: absolute;
  right: 10px;
  top: 10px;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.9);
  }
  50% {
    box-shadow: 0 0 0 8px rgba(77, 170, 87, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(77, 170, 87, 0);
  }
}

.settings__share-link {
  text-align: center;
  margin-top: 16px;
}

.settings__share-link em {
  font-weight: 300;
}

.settings__copy-link-btn {
  margin-top: 5px;
  background: none;
  border: none;
  color: white;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease-out;
  margin-right: 4px;
  padding: 0;
}

.settings__link-copied-icon {
  margin-top: 8px;
  margin-right: 3px;
}

.settings__basic-btn {
  background: none;
  border: none;
  background: white;
  color: black;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease-out;
  margin-right: 4px;
  padding: 4px 8px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  box-shadow: 0 0 4px #171818, 0 0 8px #171818, 0 0 10px #171818;
  transition: all 0.2s ease-in-out;
  font-family: "Oswald", sans-serif;
  font-weight: bold;
}

.settings__basic-btn:hover {
  background: #e2e0e0;
}

.setting__copy-link-text {
  border: 1px solid white;
  padding: 2px 6px;
  border-style: dashed;
}

.assassins--hint {
  font-size: 12px;
  margin-top: -4px;
  font-weight: lighter;
}
