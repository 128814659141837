.loading-bar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 20px;
  background-color: black;
}

.light {
  flex: 1 0 auto;
  height: 20px;
  box-shadow: 0 0 10px;
}

.red {
  background-color: #ff9494;
  box-shadow: 0 0 5px #ff4949, 0 0 10px #ff4949, 0 0 15px #ff4949,
    0 0 20px #ff4949, 0 0 25px #ff4949, 0 0 30px #ff4949, 0 0 35px #ff4949;
}

.blue {
  background-color: #83c9f8;
  box-shadow: 0 0 5px #76d6ff, 0 0 10px #76d6ff, 0 0 15px #76d6ff,
    0 0 20px #76d6ff, 0 0 25px #76d6ff, 0 0 30px #76d6ff, 0 0 35px #76d6ff;
}

.green {
  background-color: #b4f783;
  box-shadow: 0 0 5px #a0ff76, 0 0 10px #a0ff76, 0 0 15px #a0ff76,
    0 0 20px #a0ff76, 0 0 25px #a0ff76, 0 0 30px #a0ff76, 0 0 35px #a0ff76;
}

/* .sweep {
  animation: sweep-animation 2s infinite alternate;
} */

.flicker {
  animation: flicker-animation 0.1s infinite;
}

.breathe {
  animation: breathe-animation 2s infinite;
}

/* .pulse {
  animation: pulse-animation 2s infinite;
} */

@keyframes breathe-animation {
  /* breathe should be altering the glow aka box-shadow */
  0% {
    background-color: #c8e6b3;
    box-shadow: 0 0 2px #98d080, 0 0 4px #98d080, 0 0 6px #98d080,
      0 0 7px #98d080, 0 0 9px #98d080, 0 0 10px #98d080, 0 0 12px #98d080;
  }
  50% {
    background-color: #b4f783;

    box-shadow: 0 0 5px #a0ff76, 0 0 10px #a0ff76, 0 0 12px #a0ff76,
      0 0 14px #a0ff76, 0 0 16px #a0ff76, 0 0 18px #a0ff76, 0 0 21px #a0ff76;
  }
  100% {
    background-color: #c8e6b3;
    box-shadow: 0 0 2px #98d080, 0 0 4px #98d080, 0 0 6px #98d080,
      0 0 7px #98d080, 0 0 9px #98d080, 0 0 10px #98d080, 0 0 12px #98d080;
  }
}

@keyframes sweep-animation {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}

@keyframes flicker-animation {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

@keyframes pulse-animation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
