.spectators-drawer {
  background-color: rgba(44, 64, 85, 0.9);
  min-height: 200px;
  color: #fff;
  max-width: 350px;
  min-width: 200px;
  position: absolute;
  bottom: 10px;
  left: 0;
  padding: 8px;
  padding-right: 16px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  animation: slide-in--left-right 0.2s ease-out forwards;
  z-index: 2;
  transform: translateX(-100%);
}

.spectators-title {
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  padding-bottom: 8px;
  margin-bottom: 8px;
}

.spectators-drawer__close-btn {
  font-size: 20px;
  background-color: #fff;
  font-weight: bold;
  color: rgba(44, 64, 85, 1);
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}

@keyframes slide-in--left-right {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes slide-out--right-left {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.spectators-drawer--closed {
  animation: slide-out--right-left 0.2s ease-out forwards;
}
