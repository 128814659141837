.login-modal {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.login-modal h1 {
  color: black;
  margin-top: 0;
  margin-bottom: 0;
}

.login-modal h2 {
  color: var(--font-color);
  margin-top: 0;
  font-size: 20px;
}

.login-modal__content {
  box-shadow: 0 0 10px 0 rgba(255, 255, 255, 0.7);
  background-color: white;
  padding: 20px;
  margin-top: 50px;
  max-width: 400px;
  width: 100%;
  border-radius: 2px;
}

.login-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  appearance: none;
  -webkit-appearance: none;
  border: 1px solid #ccc;
  border-radius: 0;
  background-color: transparent;
  font-family: 'Oswald', sans-serif;
  text-transform: uppercase;
}

.login-btn {
  width: 100%;
  padding: 14px 10px;
  border: none;
  background-color: #2b3f54;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 4px;
  font-family: "Oswald", sans-serif;
  text-transform: uppercase;
  font-weight: bold;
}

.login-error {
  color: var(--font-color);
  margin-top: 6px;
  margin-bottom: 0;
}

.spinner {
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-top: 3px solid #fff;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}