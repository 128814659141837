.teams {
  display: flex;
  min-height: 170px;
}

.team {
  flex: 1;
  position: relative;
}

.team__container {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 5px;
}

.team--red {
  background-color: var(--red-team-color);
}

.team--blue {
  background-color: var(--blue-team-color);
}

.team--green {
  background-color: var(--green-team-color);
}

.team__remaining-count {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 38vmin;
  opacity: 0.15;
  z-index: 1;
  font-weight: 700;
}

.team__lost {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 16vmin;
  opacity: 0.3;
  z-index: 1;
  font-weight: 700;
  display: flex;
}

@media screen and (min-width: 468px) {
  .team__remaining-count {
    font-size: 14vmin;
  }
}

.spymaster-container {
  font-size: 11px;
  padding: 4px 6px 6px 6px;
  border-bottom: 2px solid #fff;
  min-height: 29px;
}

.operatives-container {
  font-size: 12px;
  padding: 6px;
}

.join-btn {
  border: none;
  color: #fff;
  margin: 0;
  padding: 0;
  font-size: 11px;
  text-decoration: underline;
  font-family: "Oswald", sans-serif;
  cursor: pointer;
}

/* media query for desktop */
@media screen and (min-width: 468px) {
  .spymaster-container {
    font-size: 14px;
    padding: 6px 8px 8px 8px;
  }

  .operatives-container {
    font-size: 16px;
    padding: 8px;
  }

  .join-btn {
    font-size: 14px;
  }
}

.hint__form button {
  border: 1px solid #fff;
  border-left: none;
  color: #fff;
}

.team--red .hint__form button {
  background-color: var(--red-team-color);
}

.team--blue .hint__form button {
  background-color: var(--blue-team-color);
}

.team--green .hint__form button {
  background-color: var(--green-team-color);
}

/* hints */

.hints {
  margin: 6px 0;
  margin-top: auto;
  padding: 0px 6px;
  padding-top: 10px;
  font-size: 12px;
  font-weight: lighter;
  text-transform: uppercase;
  margin-bottom: 8px;
}

.hint__form {
  width: 100%;
  display: flex;
  margin-bottom: 7px;
  position: relative;
  z-index: 8;
  padding: 0 4px;
  max-width: 240px;
}

.hint__form input {
  width: 100%;
  border: 1px solid #fff;
  border-radius: 0;
  background-color: transparent;
  border-right: none;
  color: #fff;
  padding: 2px;
  appearance: none;
  -webkit-appearance: none;
}

.hint__form input::placeholder {
  color: white;
}

.new-hint {
  background: white;
  display: inline-block;
  color: var(--font-color);
  padding: 1px 4px;
  padding-left: 8px;
  position: relative;
}

.new-hint::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 4px;
  background-color: orange;
}

/* players */
.player-offline {
  opacity: 0.5;
}

.icon-btn {
  font-size: 10px;
  border: none;
  background: none;
  outline: none;
  color: white;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.player-pulsing {
  animation: glowing-text 3s infinite;
}

@keyframes glowing-text {
  0% {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff;
  }
  50% {
    text-shadow: none;
  }
  100% {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff;
  }
}

.end-turn-btn {
  background-color: rgba(43, 63, 84, 0.8);
  padding: 4px;
  padding-top: 2px;
  border-radius: 2px;
  font-size: 12px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
  cursor: pointer;
  color: #fff;
  border: none;
  font-family: "Oswald", sans-serif;
  max-width: 90px;
  width: 100%;
  margin: 0 auto;
  position: relative;
}

.end-turn-btn__votes {
  position: absolute;
  display: flex;
  top: -2px;
  right: 6px;
  background-color: white;
  padding: 0 3px;
  color: black;
  font-size: 8px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.5);
}

.player__color-circle {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  margin-right: 2px;
  margin-top: 4px;
}